<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5">Centro de Custo</h1>
    <div class="grid grid-cols-2 md:grid-cols-6 gap-3">
      <router-link :to="`/centros/form`" type="button" v-if="!$store.state.user.centrocusto"
        :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
        class="mb-2 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
        <span class="inline-block mr-2">{{ $t("txt-new") }}</span>
      </router-link>
    </div>
    <div class="grid grid-cols-12 gap-3 mb-3">
      <div class="col-span-12 md:col-span-3">
        <label for="busca" class="block text-sm font-medium text-gray-700">Nome</label>
        <input v-model="busca" type="text" name="busca" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
      </div>
      <div class="col-span-12 md:col-span-2">
        <button @click="start()" type="button"
          class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
          <span class="inline-block mr-2">Buscar</span>
        </button>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nome
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="centro in list" :key="centro._id">
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ centro.nome ? centro.nome : "" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    <router-link :to="`/centros/form/${centro._id}`" type="button"
                      class="bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center">
                      <span class="inline-block">Editar</span>
                    </router-link>
                    <button v-if="!$store.state.user.centrocusto" @click="remove(centro._id)" type="button"
                      class="bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm mx-2 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center">
                      Remover
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb: [{ url: "/centros", value: "Centro de custos" }],
      list: [],
      busca: "",
      total: 0,
    };
  },
  methods: {
    async start() {
      const operadoresReq = await this.$http.post("/v1/centrocusto/list", { all: true, busca: this.busca });
      this.list = operadoresReq.data.data;
      this.total = operadoresReq.data.total;
    },

    async remove(centro) {
      if (confirm("Tem certeza que deseja excluir esse centro de custo?")) {
        const resp = await this.$http.delete(`/v1/centrocusto/${centro}`);
        if (!resp.data.success) {
          this.$vToastify.error(resp.data.err);
          return;
        }
        this.$vToastify.success("Centro removido");
        this.start();
      }
    },
  },
  async beforeMount() {
    this.start();
  },
};
</script>
